const container=[{initial:"empty"}]
   const EpisodeReducer=(state2=container,action)=>{

   // console.log("payload 2",action.payload)
     switch(action.type){

    case'EPISODE':
    return {episode:action.payload}

    default:
      return state2;

     }
   }


   export default EpisodeReducer;