const container=[{initial:"empty paid"}]
   const PaidReducer=(state=container,action)=>{

   // console.log("payload 2",action.payload)
     switch(action.type){

    case'LISTOFPAID':
    return {...state,paid:action.payload}

    default:
      return state;

     }
   }


   export default PaidReducer;