import { createStore, applyMiddleware, compose} from 'redux'
import myeducers from '../Reducer/'


    
//import reduxthunk from 'redux-thunk'

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store= createStore(myeducers,window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__&& window.__REDUX_DEVTOOLS_EXTENSION__())
// const store= createStore(myeducers,composeEnhancer(applyMiddleware(reduxthunk)))
 // store.dispatch(setProject())

     
 export default store;

