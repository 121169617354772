import StoryReducer from './StoryReducer'
 import Episodem from './EpisodeReducer'
 import RegisterReducer from './RegisterReducer'
 import PaidReducer from './PaidReducer'
import { combineReducers } from 'redux'


 const allReducers=combineReducers({
    state:StoryReducer,
    episodes:Episodem,
    users:RegisterReducer,
    paidusers:PaidReducer
})


export default allReducers;  