function setPaidAction(paid){
    //  console.log(episode)
   return {
   
       type:'LISTOFPAID',
       payload:paid,
   }
   
   }  
   
   
   
   export default setPaidAction;
   
   
   