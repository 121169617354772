import './App.css';
import React, { Suspense, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {Routes,Route} from 'react-router-dom'
import LinearProgress from '@mui/material/LinearProgress';
import { useSelector,useDispatch } from "react-redux";
import setAction from './redux/action/storyAction';
import setEpisodeaction from './redux/action/Episode';
import RegisterAction from './redux/action/RegisterAction';
import PaidActions from './redux/action/PaidActions';



import axios from 'axios';

const Welcomepage=React.lazy(()=> import('./components/Welcomepage'))
const Signin=React.lazy(()=> import('./components/Signin'))
const Middle=React.lazy(()=> import('./components/Middle'))
const Home=React.lazy(()=> import('./components/Home'))
const Fullfile=React.lazy(()=> import('./components/Fullfile'))
const Checks=React.lazy(()=> import('./components/Checks'))
const Signinuser=React.lazy(()=> import('./components/Signinuser'))
const Signout=React.lazy(()=> import('./components/Signout'))
const Signup=React.lazy(()=> import('./components/Signup'))
const UpdatePhone=React.lazy(()=> import('./components/UpdatePhone'))

function App() {

  const [story,setStory]=useState([]);
  const [episodes,setEpisodes]=useState([]);
  const [user,setUsers]=useState([]);
  const [paidl,setPaidl]=useState([]);

  //console.log("nas",process.env.REACT_APP_UNIVERSAL_URL)
  useEffect(()=>{
    axios.get(process.env.REACT_APP_UNIVERSAL_URL+"/loadStory.php").then((result)=>{
   console.log("ram Niger:",result)
  // console.log("ram Niger:",process.env.REACT_APP_UNIVERSAL_URL)
        // setStatep(result.data)

        if(result.data.length>=1){
          //  setBigtosmall(1)
            setStory(result.data)
         // console.log("jakoli:::",result.data)
        } else{          //  setBigtosmall(0)
        }
     });


     axios.get(process.env.REACT_APP_UNIVERSAL_URL+"/loadEpisode.php").then((result)=>{
     console.log("Episode 111:",result)
     // console.log("ram Niger:",process.env.REACT_APP_UNIVERSAL_URL)
           // setStatep(result.data)
   
           if(result.data.length>=1){
             //  setBigtosmall(1)
             setEpisodes(result.data)
            // console.log("jakoli:::",result.data)
           } else{          //  setBigtosmall(0)
           }
        });


        axios.get(process.env.REACT_APP_UNIVERSAL_URL+"/userlist.php").then((result)=>{
          console.log("User list:",result)
          // console.log("ram Niger:",process.env.REACT_APP_UNIVERSAL_URL)
                // setStatep(result.data)
        
                if(result.data.length>=1){
                  //  setBigtosmall(1)
                  setUsers(result.data)
                 // console.log("jakoli:::",result.data)
                } else{          //  setBigtosmall(0)
                }
             });



             axios.get(process.env.REACT_APP_UNIVERSAL_URL+"/paidlist.php").then((result)=>{
              console.log("User list:",result)
              // console.log("ram Niger:",process.env.REACT_APP_UNIVERSAL_URL)
                    // setStatep(result.data)
            
                    if(result.data.length>=1){
                      //  setBigtosmall(1)
                      setPaidl(result.data)
                     // console.log("jakoli:::",result.data)
                    } else{          //  setBigtosmall(0)
                    }
                 });
   
   


    },[])

    

  const dispatch=useDispatch();      
dispatch(setAction(story))
dispatch(setEpisodeaction(episodes))
dispatch(RegisterAction(user))
dispatch(PaidActions(paidl))
//localStorage.removeItem("userid");


// let arrayk=[{name:'Soja',age:40,Experiance:'Programmmer'},
// {name:'Sadiq',age:40,Experiance:'Java'},
// {name:'Larai',age:40,Experiance:'Python'}];


// for(let i=0; i<arrayk.length; i++){

//  // console.log("map:",arrayk[i])


// }

// let k=arrayk.map((u)=>{

// // console.log(u)

//   return u.name;
// })



 console.log("Entry point!")
  return (
    <Routes>
 

 


    <Route path="/"  element={ <Suspense fallback={<div> <Box sx={{ width: '100%' }}>
       <LinearProgress /> 
     </Box></div>} >
       <Welcomepage/> 
     
     
     </Suspense>}  >
     </Route>



         <Route path="/signout"  element={ <Suspense fallback={<div> <Box sx={{ width: '100%' }}>
       <LinearProgress /> 
     </Box></div>} >
       <Signout/> 
     
     
     </Suspense>}  >
     </Route>


     

     <Route path="/updatePhone"  element={ <Suspense fallback={<div> <Box sx={{ width: '100%' }}>
       <LinearProgress /> 
     </Box></div>} >
       <UpdatePhone/> 
     
     
     </Suspense>}  >
     </Route>



     <Route path="/signup"  element={ <Suspense fallback={<div> <Box sx={{ width: '100%' }}>
       <LinearProgress /> 
     </Box></div>} >
       <Signup/> 
     
     
     </Suspense>}  >
     </Route>
     

     <Route path="/signinuser"  element={ <Suspense fallback={<div> <Box sx={{ width: '100%' }}>
       <LinearProgress /> 
     </Box></div>} >
       <Signinuser/> 
     
     
     </Suspense>}  >
     </Route>

     
 
 
     <Route path="/signin"  element={ <Suspense fallback={<div> <Box sx={{ width: '100%' }}>
       <LinearProgress /> 
     </Box></div>} >
       <Signin/> 
     
     
     </Suspense>}  >
     </Route>
 
     
     <Route path="/fullfile/:id/:id2/:paidid"  element={ 
     <Suspense fallback={<div> <Box sx={{ width: '100%' }}>
       <LinearProgress /> 
     </Box></div>} >
     
       <Fullfile/> 
     
     
     </Suspense>}  >
     </Route>
 
     
     <Route path="/middle"  element={ <Suspense fallback={<div> <Box sx={{ width: '100%' }}>
       <LinearProgress /> 
     </Box></div>} >
       <Middle/> 
     
     
     </Suspense>}  >
     </Route>
     
 
 
      
     <Route path="/home"  element={ <Suspense fallback={<div> <Box sx={{ width: '100%' }}>
       <LinearProgress /> 
     </Box></div>} >
       <Home/> 
     
     
     </Suspense>}  >
     </Route>
     
 
 
     <Route path="/checks/:episodeID/:storyid"  element={ <Suspense fallback={<div> <Box sx={{ width: '100%' }}>
       <LinearProgress /> 
     </Box></div>} >
       <Checks/> 
     
     
     </Suspense>}  >
     </Route>
     </Routes>
  );
}

export default App;
