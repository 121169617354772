let initialState=[{initial:'Empty Register'}]
const RegisterReducer=(state=initialState,action)=>{

  switch(action.type){
    case 'LOADREGISTER':    
        return {register:action.payload}

        default:
          return state;
  }

  
  
}



export default RegisterReducer;