import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import { Provider } from 'react-redux';// is responsible of linking our reduce store with all the components in our appllication 
import store from './redux/store/store'
import '@fontsource/public-sans';
import { GoogleOAuthProvider } from '@react-oauth/google';

<link
  rel="stylesheet"
  href="https://fonts.googleapis.com/css?family=Public+Sans&display=swap"
/>


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <BrowserRouter>
     <Provider store={store}>
     <GoogleOAuthProvider clientId="55129238810-8hekolj4ja6vka1a4kc6buvo88sf2d0f.apps.googleusercontent.com">
    <App />
    </GoogleOAuthProvider>
    </Provider>
    </BrowserRouter>
  </React.StrictMode>
);  

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
