function setEpisode(episode){
    //  console.log(episode)
   return {
   
       type:'EPISODE',
       payload:episode,
   }
   
   }  
   
   
   
   export default setEpisode;
   
   
   