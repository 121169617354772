let initialState=[{initial:'Empty Story'}]
const StoryReducer=(state=initialState,action)=>{

  switch(action.type){
    case 'LOADSTORY':    
        return {story:action.payload}

        default:
          return state;
  }

  
  
}



export default StoryReducer;