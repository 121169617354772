function RegisterAction(users){
     console.log("payload vas",users)
   return {
   
       type:'LOADREGISTER',
       payload:users,
   }
   
   }
   
   
   
   
   
   export default RegisterAction;
   
   
   