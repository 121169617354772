function setStory(story){
    //  console.log("payload",projects)
   return {
   
       type:'LOADSTORY',
       payload:story,
   }
   
   }
   
   
   
   
   
   export default setStory;
   
   
   